/* Disable text selection on the entire body of the website */
body {
  -webkit-user-select: none;  /* Chrome, Safari, and Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* Internet Explorer/Edge */
  user-select: none;          /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}

.App {
  position: relative;
  text-align: center;
  /* background-color: #d32929; */
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.ScoreBoardWrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.ScoreBoardWrapper img {
  height: 50px;
}

.ScoreBoardWrapper .Logo {
  display: flex;
  align-items: center; 
  justify-content: center; 
}

.ScoreBoard {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.ScoreBoard h2 {
  margin-right: 30px;
  text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
}

.ScoreBoard h2 span {
  color: blue;
}

.ScoreBoard h2 .LifeBox {
  display: inline-block;
  width: 20px; /* Box size */
  height: 20px;
  border: 2px solid #FFF; /* Box outline */
  margin: 2px;
  margin-bottom: -4px;
}

.ScoreBoard h2 .LifeBox.Filled {
  background-color: black; /* Filled box color */
}

.GameBoard {
  /* background-color: #ffffe0; */
  border: 3px solid #6EAADC;
  border-radius: 20px;
  background: radial-gradient(69.76% 52.94% at 50% 50%, #000 0%, #101131 50%, rgba(3, 4, 30, 0.98) 100%);
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  margin: 20px;
  min-height: 40vh;
  min-width: 20vh;
  max-width: 20vw;
  overflow: hidden;
  position: relative;
}

.Circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  position: absolute;
  font-size: x-large;
  text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
  cursor: default;
}

.Circle.Product {
  cursor: pointer;
}

.Circle.Clicked {
  box-shadow: 0 0 25px 20px rgba(145, 219, 7, .8);
  cursor: default !important;
}

.Circle.Clicked.Flashing {
  box-shadow: 0 0 25px 20px rgba(107,202,252, .8);
}

.Circle.Clicked.Flashing.Wrong {
  box-shadow: 0 0 25px 20px rgba(255, 49, 98, .8);
}

.Circle.Clicked.Wrong {
  box-shadow: 0 0 25px 20px rgba(255, 49, 98, .8);
}

.Circle.Clicked.Correct {
  box-shadow: none;
}

.Circle.Flashing {
  /* animation: flash 0.8s linear infinite; */
  cursor: pointer;
}

.CircleTimer {
  font-size: x-small;
  display: block;
}

.Overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101; /* Ensure it's above other elements */

  /* Styling for the overlay */
  /* background-color: rgba(0, 0, 0, 0.7); */
  text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
}

.OverlayMessage {
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-size: 10vw;
  z-index: 999;
}

.GameOver {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Ensure it's above other elements */
  
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.GameOverMessage {
  text-align: center;
  font-size: 6em;
  text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
}

.GameOverMessage h2 {
  margin-bottom: -150px;
}

.GameOverMessage p {
  text-shadow: -1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red, 1px 1px 0 red;
}