@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@font-face {
  font-family: "LuckiestGuy";
  src: url("../../public/assets/font/LuckiestGuy-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public/assets/font/helvetica-neue.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public/assets/font/helvetica-neue-italic.ttf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public/assets/font/helvetica-neue-bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../../public/assets/font/helvetica-neue-bold-italic.ttf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* Track */
  /* Handle */
}
*::-webkit-scrollbar {
  width: 9px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: #0091DF;
  border-radius: 10px;
}

@media (max-width: 992px) {
  *::-webkit-scrollbar {
    width: 0;
  }
}

img {
  max-width: auto !important;
}

html,
body {
  margin: 0;
  background-color: #000;
  height: 100vh;
  width: 100%;
}

body {
  height: 100vh;
  overflow: hidden;
  font-family: "Helvetica Neue", sans-serif;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.background-image {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@media (min-width: 993px) {
  .background-image.animated {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    -webkit-animation: bg_animated 2s forwards ease;
            animation: bg_animated 2s forwards ease;
  }
}

.bg-overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* img {
  max-width: 100%;
} */

a {
  color: #6EAADC;
  text-decoration: none;
}

button {
  background-color: transparent;
  padding: 0;
  border: none;
  margin: 0;
  cursor: pointer;
}

input[type=text], input[type=email], input[type=tel] {
  /* height: 99px; */
  /* font-size: clamp(15px, 0.9375rem + (1vw - 7.67px) * 3.4642, 30px); */
  text-align: center;
  color: #fff;
  background-color: transparent;
  border: 3px solid #0091DF;
  border-radius: 20px;
  outline: none;
}
@media (max-width: 992px) {
  input[type=text], input[type=email], input[type=tel] {
    height: 40px;
    border-width: 1px;
    border-radius: 10px;
  }
}
input[type=text]::-webkit-input-placeholder, input[type=email]::-webkit-input-placeholder, input[type=tel]::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
input[type=text]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=tel]::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
input[type=text]:-ms-input-placeholder, input[type=email]:-ms-input-placeholder, input[type=tel]:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
input[type=text]::-ms-input-placeholder, input[type=email]::-ms-input-placeholder, input[type=tel]::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
input[type=text]::placeholder, input[type=email]::placeholder, input[type=tel]::placeholder {
  color: #fff;
  opacity: 1;
}

textarea {
  height: 9.322vw;
  padding: 20px;
  /* font-size: clamp(15px, 0.9375rem + (1vw - 7.67px) * 3.4642, 30px); */
  font-size: 1.5625vw;
  text-align: center;
  color: #fff;
  background-color: transparent;
  border: 3px solid #0091DF;
  border-radius: 20px;
  font-family: "Helvetica Neue", sans-serif;
}
@media (max-width: 992px) {
  textarea {
    height: 40px;
    border-width: 1px;
    border-radius: 10px;
    padding: 8px;
  }
}
textarea::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
textarea::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
textarea:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
textarea::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

textarea::placeholder {
  color: #fff;
  opacity: 1;
}

.page-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 2.08vw 5.21vw 1.04vw;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}
@media (max-width: 1400px) {
  .page-container {
    padding: 40px 20px 20px;
  }
}
@media (max-width: 992px) {
  .page-container {
    padding: 20px;
  }
}
.page-container .inner {
  width: 830px;
  max-width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1em;
  font-weight: 400;
}

p, li {
  margin: 0;
  line-height: 1.4em;
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.font-150 {
  font-size: clamp(65px, 4.0625rem + (1vw - 7.67px) * 19.6305, 150px);
}

.font-65 {
  font-size: clamp(40px, 2.5rem + (1vw - 7.67px) * 5.7737, 65px);
}

.font-50 {
  font-size: 50px;
}

.font-45 {
  font-size: clamp(28px, 1.75rem + (1vw - 7.67px) * 3.9261, 45px);
}

.font-40 {
  font-size: clamp(25px, 1.5625rem + (1vw - 7.67px) * 3.4642, 40px);
}

.font-30 {
  font-size: clamp(15px, 0.9375rem + (1vw - 7.67px) * 3.4642, 30px);
}

.font-20 {
  font-size: clamp(12px, 0.75rem + (1vw - 7.67px) * 1.8476, 20px);
}

.font-15 {
  font-size: clamp(10px, 0.625rem + (1vw - 7.67px) * 1.1547, 15px);
}

.lucky-guy {
  font-family: "LuckiestGuy", serif;
}

.text-center {
  text-align: center;
}

.text-green {
  color: #89D329;
}

.text-red {
  color: #FF1E1E;
}

header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 992px) {
  /* header a:first-child img {
    width: 90px;
  }
  header a:last-child img {
    width: 43px;
  } */
}
header.single-image {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 992px) {
  header.single-image {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  header.single-image img {
    /* max-width: 43px; */
  }
}
header.header-play {
  position: relative;
}
header.header-play a:first-child {
  margin-right: 20px;
}
header.header-play a:last-child {
  margin-left: 20px;
}
header.header-play .score {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
header.header-play .score p:first-child {
  margin-right: 10px;
}
header.header-play .score p:last-child {
  margin-left: 30px;
}
header.header-play .score .lives-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
header.header-play .score .lives-container .lives {
  width: 2.55vw;
  height: 2.55vw;
  position: relative;
}
header.header-play .score .lives-container .lives img {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 46px;
  height: 46px; */
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.header-play .score .lives-container .lives .lives-red {
  opacity: 0;
}
header.header-play .score .lives-container .lives.dead .lives-green {
  opacity: 0;
}
header.header-play .score .lives-container .lives.dead .lives-red {
  opacity: 1;
}

@-webkit-keyframes bounce_in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce_in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes pop_in {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  30% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(-50%, -80%);
            transform: translate(-50%, -80%);
  }
  100% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
@keyframes pop_in {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
  30% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(-50%, -80%);
            transform: translate(-50%, -80%);
  }
  100% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
@-webkit-keyframes bounce_up {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes bounce_up {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bg_animated {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes bg_animated {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
  }
}
.splash header {
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .splash header {
    margin-bottom: 0;
  }
}
.splash header img {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: bounce_in 1s ease forwards;
          animation: bounce_in 1s ease forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.splash .content__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 992px) {
  .splash .content__top img {
    max-width: 325px;
  }
}
.splash .content__top .matchem-logo {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: bounce_in 1s ease forwards;
          animation: bounce_in 1s ease forwards;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.splash .content__top .play-desktop {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: bounce_in 1s ease forwards;
          animation: bounce_in 1s ease forwards;
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}
.splash .content__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: start;
  height: 16vw;
  overflow: hidden;
  gap: 6vw;
}
@media (max-width: 992px) {
  .splash .content__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: unset;
    overflow: unset;
  }
}
.splash .content__bottom .col-1 {
  margin-top: 40px;
  margin-right: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 62.5vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  justify-content: end;
}
@media (max-width: 992px) {
  .splash .content__bottom .col-1 {
    margin: 0;
    width: 280px;
    max-width: 100%;
  }
}
.splash .content__bottom .col-1 p {
  margin-bottom: 10px;
  opacity: 0;
  -webkit-animation: fade_in 1s forwards ease;
          animation: fade_in 1s forwards ease;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
@media (max-width: 992px) {
  .splash .content__bottom .col-1 p {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    text-align: center;
  }
}
.splash .content__bottom .col-1 .image-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 2vw;
}
.splash .content__bottom .col-1 .image-grid img {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  opacity: 0;
  margin-bottom: -10px;
  width: 8vw;
}
.splash .content__bottom .col-1 .image-grid img.show {
  -webkit-animation: bounce_up 1s forwards ease;
          animation: bounce_up 1s forwards ease;
}
@media (max-width: 1300px) {
  .splash .content__bottom .col-1 .image-grid img {
    width: 120px;
  }
}
@media (max-width: 992px) {
  .splash .content__bottom .col-1 .image-grid {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
  .splash .content__bottom .col-1 .image-grid img {
    width: 75px;
    -ms-grid-column-align: center;
        justify-self: center;
  }
}
.splash .content__bottom .col-2 {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: bounce_in 1s forwards ease;
          animation: bounce_in 1s forwards ease;
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}
@media (max-width: 1300px) {
  .splash .content__bottom .col-2 img {
    width: 240px;
  }
}
@media (max-width: 992px) {
  .splash .content__bottom .col-2 img {
    width: 130px;
    margin-top: 10px;
  }
}

@media (max-width: 992px) {
  .splash .play-desktop {
    display: none;
  }
}
.splash .play-mobile {
  display: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: bounce_in 1s forwards ease;
          animation: bounce_in 1s forwards ease;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}
@media (max-width: 992px) {
  .splash .play-mobile {
    display: inline-block;
    margin: 0 auto;
  }
  .splash .play-mobile img {
    max-width: 150px;
  }
}

.page-signup .inner {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 22%;
  max-width: calc(100vw - 40px);
  width: 39.4vw;
  /* fixed left-1/2 top-[30%] transform -translate-x-1/2 w-[39.4vw] */
}
@media (max-width: 992px) {
  .page-signup .inner {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 92vw;
  }
}
.page-signup .inner.left {
  left: -200%;
}
.page-signup .inner.right {
  left: 200%;
}
.page-signup .signup h2 {
  margin-bottom: 50px;
}
@media (max-width: 992px) {
  .page-signup .signup h2 {
    margin-bottom: 30px;
  }
}
.page-signup .signup form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  width: 100%;
}
.page-signup .signup form input {
  /* width: 718px; */
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.page-signup .signup form input:not(:last-child) {
  /* margin-bottom: 40px; */
}
@media (max-width: 992px) {
  .page-signup .signup form input:not(:last-child) {
    /* margin-bottom: 20px; */
  }
}
.page-signup .signup form p {
  /* max-width: 771px; */
  margin: 0 auto 60px;
}
@media (max-width: 992px) {
  .page-signup .signup form p {
    margin: 10px auto 30px;
  }
}
.page-signup .signup form button {
  width: 250px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .page-signup .signup form button {
    width: 150px;
  }
}
.page-signup .tnc h2 {
  margin-bottom: 40px;
}
.page-signup .tnc p {
  margin-bottom: 20px;
}
.page-signup .tnc button {
  margin-top: 20px;
}
.page-signup .recommend .inner__logo {
  width: 9.895vw;
  max-width: 9.895vw;
  margin: -30px auto 10px;
}
@media (max-width: 992px) {
  .page-signup .recommend .inner__logo {
    width: 25.64vw;
    max-width: 25.64vw;
    margin: -30px auto 0;
  }
}
.page-signup .recommend h2 {
  /* max-width: 498px; */
  margin: 0 auto 70px;
}
@media (max-width: 992px) {
  .page-signup .recommend h2 {
    max-width: 360px;
    margin: 0 auto 30px;
  }
}
@media (max-width: 600px) {
  .page-signup .recommend h2 {
    max-width: 333px;
  }
}
.page-signup .recommend .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 2vw auto 0;
}
@media (max-width: 992px) {
  .page-signup .recommend .button-container button {
    max-width: 150px;
  }
}
.page-signup .recommend .button-container button:not(:last-child) {
  margin-right: 50px;
}
@media (max-width: 992px) {
  .page-signup .recommend .button-container button:not(:last-child) {
    margin-right: 30px;
  }
}
.page-signup .why .inner__logo {
  width: 9.895vw;
  max-width: 9.895vw;
  margin: -30px auto 10px;
}
@media (max-width: 992px) {
  .page-signup .why .inner__logo {
    width: 38.461vw;
    max-width: 38.461vw;
    margin: -30px auto 0;
  }
}
.page-signup .why h2 {
  /* max-width: 598px; */
  margin: 0 auto 50px;
}
@media (max-width: 992px) {
  .page-signup .why h2 {
    margin: 0 auto 30px;
  }
}
.page-signup .why form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page-signup .why form textarea {
  margin-bottom: 60px;
}
@media (max-width: 992px) {
  .page-signup .why form textarea {
    margin-bottom: 30px;
  }
}
.page-signup .why form button {
  max-width: 300px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .page-signup .why form button {
    max-width: 150px;
  }
}
.page-signup .why-not .inner__logo {
  width: 9.895vw;
  max-width: 9.895vw;
  margin: -30px auto 10px;
}
@media (max-width: 992px) {
  .page-signup .why-not .inner__logo {
    width: 25.64vw;
    max-width: 25.64vw;
    margin: -30px auto 0;
  }
}
.page-signup .why-not h2 {
  /* max-width: 598px; */
  margin: 0 auto 50px;
}
@media (max-width: 992px) {
  .page-signup .why-not h2 {
    margin: 0 auto 30px;
  }
}
.page-signup .why-not form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page-signup .why-not form textarea {
  margin-bottom: 60px;
}
@media (max-width: 992px) {
  .page-signup .why-not form textarea {
    margin-bottom: 30px;
  }
}
.page-signup .why-not form button {
  max-width: 300px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .page-signup .why-not form button {
    max-width: 150px;
  }
}
.page-signup .competitor {
  width: 58.9vw;
}
.page-signup .competitor .inner__logo {
  width: 9.895vw;
  max-width: 9.895vw;
  margin: -30px auto 10px;
}
@media (max-width: 992px) {
  .page-signup .competitor {
    width: 100vw;
  }
  .page-signup .competitor .inner__logo {
    /* width: 100px; */
    width: 25.64vw;
    max-width: 25.64vw;
    margin: -30px auto 0;
  }
}
.page-signup .competitor h2 {
  max-width: 58.9vw;
  margin: 0 auto 50px;
}
@media (max-width: 992px) {
  .page-signup .competitor h2 {
    max-width: 87vw;
    margin: 0 auto 30px;
  }
}
.page-signup .competitor form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page-signup .competitor form textarea {
  width: 40.833vw;
  max-width: calc(100% - 20px);
  margin: 0 auto 60px;
}
@media (max-width: 992px) {
  .page-signup .competitor form textarea {
    width: 87%;
    margin-bottom: 30px;
  }
}
.page-signup .competitor form button {
  max-width: 300px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .page-signup .competitor form button {
    max-width: 150px;
  }
}

.how-to-play .progress-loader {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.3);
          transform: translate(-50%, -50%) scale(1.3);
}
.how-to-play .progress-loader .progress {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.how-to-play .progress-loader .progress svg {
  -webkit-transform: rotate(90deg) scale(-1);
          transform: rotate(90deg) scale(-1);
}
.how-to-play .progress-loader .progress svg #svgCircleProgressPath1 {
  -webkit-transform-origin: center;
          transform-origin: center;
}
.how-to-play .progress-loader .progress #counter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 25px;
}
.how-to-play .progress-loader .progress #counter img {
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: none;
  -o-object-fit: contain;
     object-fit: contain;
}
.how-to-play .progress-loader .progress #counter img.active {
  display: block;
}
.how-to-play .container-play {
  margin-top: 50px;
}
.how-to-play .container-play .row-howtoplay {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40% 10% 50%;
  grid-template-columns: 40% 50%;
  grid-gap: 10%;
}
@media (max-width: 1024px) {
  .how-to-play .container-play .row-howtoplay {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    grid-gap: 30px;
  }
}
.how-to-play .container-play .row-howtoplay .set-column h1 {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .set-column h1 {
    text-align: center;
    /* margin-bottom: 0; */
  }
}
.how-to-play .container-play .row-howtoplay .set-column .description-dekstop p {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .set-column .description-dekstop p {
    display: none;
  }
}
.how-to-play .container-play .row-howtoplay .set-column .description-mobile p {
  display: none;
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .set-column .description-mobile p {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.how-to-play .swiper {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 3.125vw;
}

.how-to-play .container-play .row-howtoplay .howto-swiper {
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .howto-swiper {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .howto-swiper .swiper-slide {
    text-align: center;
  }
}
.how-to-play .container-play .row-howtoplay .howto-swiper .swiper-slide img {
  width: 100%;
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .howto-swiper .swiper-slide img {
    width: 213px;
  }
}

.how-to-play .swiper-pagination .swiper-pagination-bullet {
  width: 0.9375vw;
  height: 0.9375vw;
  background-color: #535353;
  opacity: 1 !important;
  margin: 0 0.625vw;
}

.how-to-play .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.625vw;
}

.how-to-play .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #D9D9D9;
}

.how-to-play .container-play .row-howtoplay .howto-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  background-color: #535353;
  opacity: 1 !important;
  margin: 0 12px;
}
@media (max-width: 767px) {
  .how-to-play .container-play .row-howtoplay .howto-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
}
.how-to-play .container-play .row-howtoplay .howto-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #D9D9D9;
}
.how-to-play .container-play .start-button {
  width: 100%;
  margin-top: 50px;
}
.how-to-play .container-play .start-button img {
  width: 250px;
  margin: auto;
}

.leaderboard-inner {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 26.8vw 15% 1fr;
  grid-template-columns: 26.8vw 1fr;
  gap: 15%;
}
@media (max-width: 1750px) {
  .leaderboard-inner {
    gap: 30px;
  }
}
@media (max-width: 1024px) {
  .leaderboard-inner {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
.leaderboard-inner .col-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 1024px) {
  .leaderboard-inner .col-1 {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.leaderboard-inner .col-1 h1 {
  margin-bottom: 130px;
}
@media (max-width: 1024px) {
  .leaderboard-inner .col-1 h1 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
    margin-bottom: 0;
    margin-top: 30px;
  }
}
.leaderboard-inner .col-1 h2 {
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .leaderboard-inner .col-1 h2 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .leaderboard-inner .col-1 h3 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 30px;
  }
}
@media (max-width: 1024px) {
  .leaderboard-inner .col-1 a {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .leaderboard-inner .col-1 a img {
    width: 195px;
  }
}
.leaderboard-inner .leaderboard-table {
  border: 2px solid #6EAADC;
  border-radius: 20px;
  padding: 25px 0 20px;
  height: 24.479vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  .leaderboard-inner .leaderboard-table {
    /* height: 314px; */
    height: auto;
    min-height: 50vh;
  }
}
.leaderboard-inner .leaderboard-table .table-head,
.leaderboard-inner .leaderboard-table .table-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 35% 45% 20%;
  grid-template-columns: 35% 45% 20%;
}
@media (max-width: 1550px) {
  .leaderboard-inner .leaderboard-table .table-head,
.leaderboard-inner .leaderboard-table .table-item {
    -ms-grid-columns: 130px 20px 1fr 20px 150px;
    grid-template-columns: 130px 1fr 150px;
    gap: 20px;
  }
}
@media (max-width: 567px) {
  .leaderboard-inner .leaderboard-table .table-head,
.leaderboard-inner .leaderboard-table .table-item {
    -ms-grid-columns: 60px 1fr 70px;
    grid-template-columns: 60px 1fr 70px;
  }
}
.leaderboard-inner .leaderboard-table .table-head > div:first-child,
.leaderboard-inner .leaderboard-table .table-item > div:first-child {
  padding-left: 26%;
}
@media (max-width: 1750px) {
  .leaderboard-inner .leaderboard-table .table-head > div:first-child,
.leaderboard-inner .leaderboard-table .table-item > div:first-child {
    padding-left: 20px;
  }
}
.leaderboard-inner .leaderboard-table .table-head {
  padding-right: 24px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .leaderboard-inner .leaderboard-table .table-head {
    margin-bottom: 8px;
  }
}
.leaderboard-inner .leaderboard-table .table-head::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.leaderboard-inner .leaderboard-table .table-item {
  margin-bottom: 11px;
}
@media (max-width: 1024px) {
  .leaderboard-inner .leaderboard-table .table-item {
    margin-bottom: 8px;
  }
}
.leaderboard-inner .leaderboard-table .table-content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  padding-right: 24px;
}
.leaderboard-inner .leaderboard-table .table-content-holder {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: scroll;
}
.leaderboard-inner .description p {
  margin-bottom: 15px;
}
.leaderboard-inner .description a {
  text-decoration: underline;
}

.play {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.play .play-canvas {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 11px 1fr 11px 1fr 11px 1fr;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.572vw;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.play .play-canvas .box {
  position: relative;
  width: 100%;
  height: 100%;
  border: 3px solid #6EAADC;
  border-radius: 20px;
  overflow: hidden;
  background: radial-gradient(69.76% 52.94% at 50% 50%, #000 0%, #101131 50%, rgba(3, 4, 30, 0.98) 100%);
}

.play .play-canvas .box.correct {
  border-color: rgb(145, 219, 7);
  box-shadow: inset 0 0 15px 10px rgba(145, 219, 7, .8);
}

.play .play-canvas .box.wrong {
  border-color: rgb(255, 49, 98);
  box-shadow: inset 0 0 15px 10px rgba(255, 49, 98, .8);
}

.play .play-canvas .box .image {
  position: absolute;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.play .play-canvas .box .image img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -o-object-fit: contain;
     object-fit: contain;
}
.play .play-canvas .box .image .bullet {
  opacity: 1;
  cursor: pointer;
  visibility: visible;
}
.play .play-canvas .box .image .pop {
  opacity: 0;
  visibility: hidden;
}
.play .play-canvas .box .image.active .bullet {
  opacity: 0;
  visibility: hidden;
}
.play .play-canvas .box .image.active .pop {
  opacity: 1;
  -webkit-transform: scale(0) translate(-50%, -50%);
          transform: scale(0) translate(-50%, -50%);
  visibility: visible;
  -webkit-animation: pop_in 1s ease forwards;
          animation: pop_in 1s ease forwards;
}
.play .play-canvas .box .image-1 {
  top: -2%;
  right: 0%;
  width: 215px;
  height: 215px;
}
.play .play-canvas .box .image-2 {
  top: 48%;
  left: 0%;
  width: 195px;
  width: 195px;
}
.play .play-canvas .box .image-3 {
  top: 48%;
  right: -13%;
  width: 195px;
  width: 195px;
}
.play .play-canvas .box .image-4 {
  top: 75%;
  right: -10%;
  width: 195px;
  width: 195px;
}
.play .play-canvas .box .image-5 {
  top: 90%;
  left: -13%;
  width: 195px;
  width: 195px;
}
/* .play .game-over-popup {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
} */
.play .game-over-popup h2 {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  -webkit-text-stroke-width: 16px;
  -webkit-text-stroke-color: #89D329;
  font-family: "Luckiest Guy";
  font-size: 10.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 135px;
}
@media (max-width: 992px) {
  .play .game-over-popup h2 {
    font-size: 60px;
    -webkit-text-stroke-width: 3px;
    margin-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .play .game-over-popup a img {
    max-width: 150px;
  }
}
.play.game-over .bg-overlay {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
}
.play.game-over .header-play {
  z-index: 3;
}
.play.game-over .play-canvas {
  z-index: 1;
}
.play.game-over .game-over-popup {
  opacity: 1;
  visibility: visible;
  z-index: 3;
  -webkit-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
}